import React, { useRef, useState } from 'react';
import { Radio, Divider, RadioGroup, DialogContentText, Box, Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, TextField, TextareaAutosize, Typography, FormGroup, FormControlLabel, Checkbox, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { userStyle } from '../../pages/PageStyle';
import { handleApiError } from "../../components/Errorhandling";
import ErrorDialog from "../../components/Errordialog";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import axios from 'axios';
import { SERVICE } from "../../services/Baseservice";
import Qrcodegenerate from './Qrcode';
import Qrcodegeneratesize2 from './Qrcodesize2';
import Qrcodegeneratesize3 from './Qrcodesize3';
import Qrcodegeneratesize4 from './Qrcodesize4';
import { AiOutlineSetting } from 'react-icons/ai';
import ClearIcon from '@mui/icons-material/Clear';
import { useReactToPrint } from "react-to-print";

const ProductDetails = () => {

    const [product, setProduct] = useState({
        businessname: "", category: "", subcategory: "", brand: "", subbrand: "", style: "", size: "", color: "", productname: "",
        sku: "", rack: "", supplier: "", purchaserate: "", alpharate: "", discounttype: "", discountprice: "", sellingprice: '',
        quantity: '', from: "", to: 0, snno: ""
    });

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    const componentRef = useRef();
    const [productLabel, setProductLabel] = useState({
        barcodesetting: "size1",
        isProductLocation: true, isRrack: false, isRrackProductCode: true, isProductCode: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductShortnameCategorySubcategory: true, isProductSize: false, isProductBrand: false, isProductSubBrand: false, isProductSizeSubbrand: false, isProductBrandSubbrand: false, isProductSizeBrand: false, isProductSizeBrandSubbrand: true, isProductNumberAlpha: true, isNumber: false, isAlpha: false, isProductSellingPrice: true, isProductDiscPrice: true,
        isCateSubcateCount: false, isCateSubcateCountNumberAlpha: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: true
    });
    const [isQrCodePreview, setIsQrCodePreview] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    // print label modal
    const handleOpenModal = () => {

        if (product.businessname.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Shop Name!"}</p>
                </>
            );
            handleClickOpen();

        }
        else if (product.category.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Category!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.sku.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Product Code!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.supplier.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Supplier Name!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.purchaserate.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Purchase Rate!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.alpharate.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Alpha Rate!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.sellingprice.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Selling Price!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.from.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter From!"}</p>
                </>
            );
            handleClickOpen();
        }
        else if (product.quantity.length == 0) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Please Enter Quantity / To!"}</p>
                </>
            );
            handleClickOpen();
        } else {
            setOpenModal(true)
        }
    };
    const handleCloseModal = () => { setOpenModal(false); };

    const getAlpha = (e) => {
        const productCopy = { ...product };

        // Use the correct property name 'purchaserate'
        const purchaselength = productCopy.purchaserate.toString().includes('.') ?
            productCopy.purchaserate.toString().split('.')[0].length :
            productCopy.purchaserate.toString().length;

        const eventValue = e.target.value;

        if (eventValue.length <= purchaselength) {
            // Use a regular expression to filter out non-alphabetic characters
            let num = eventValue.slice(0, parseInt(purchaselength)).replace(/[^a-zA-Z]/g, '');

            // Convert to uppercase
            num = num.toUpperCase();

            productCopy.alpharate = num;

            setProduct(productCopy);
        }
    };



    let productCount = [];

    productCount.push(product);

    const checkAllData = productCount.flatMap((data) => {
        const endCount = Number(data.from) <= Number(data.quantity) ? Number(data.quantity) + Number(data.from - 1) : Number(data.from) >= Number(data.quantity) ? Number(data.quantity) + Number(data.from - 1) : Number(data.quantity);
        const startCount = Number(data.from);

        const generatedProducts = [];
        for (let i = startCount; i <= endCount; i++) {
            if (data.quantity === "") {
                generatedProducts.push({
                    businessname: data.businessname,
                    category: data.category,
                    subcategory: data.subcategory,
                    brand: data.brand,
                    subbrand: data.subbrand,
                    style: data.style,
                    size: data.size,
                    color: data.color,
                    productname: data.productname,
                    sku: data.sku,
                    rack: data.rack,
                    supplier: data.supplier,
                    purchaserate: data.purchaserate,
                    alpharate: data.alpharate,
                    discountprice: data.discountprice,
                    sellingprice: data.sellingprice,
                    quantity: data.quantity
                });
            } else {
                generatedProducts.push({
                    businessname: data.businessname,
                    category: data.category,
                    subcategory: data.subcategory,
                    brand: data.brand,
                    subbrand: data.subbrand,
                    style: data.style,
                    size: data.size,
                    color: data.color,
                    productname: data.productname,
                    sku: data.sku,
                    rack: data.rack,
                    supplier: data.supplier,
                    purchaserate: data.purchaserate,
                    alpharate: data.alpharate,
                    discountprice: data.discountprice,
                    sellingprice: data.sellingprice,
                    quantity: data.quantity,
                    snno: parseInt(`${i}`),
                });
            }
        }
        return generatedProducts;
    });


    const handleClear = () => {
        setProduct({
            ...product, businessname: "", category: "", subcategory: "", brand: "", subbrand: "", style: "", size: "", color: "", productname: "",
            sku: "", rack: "", supplier: "", purchaserate: "", alpharate: "", discountprice: "", sellingprice: '',
            quantity: '', from: "", to: 0, snno: ""
        })
    }

    const exceptThisSymbolsDot = ["e", "E", "+", "-"];
    const exceptThisSymbols = ["e", "E", "+", "-", "."];



    // store product data
    const sendRequest = async () => {


        try {
            let PRODUCT_REQ = await axios.post(SERVICE.LABEL_CREATE, {

                shopname: product.businessname,
                category: product.category,
                subcategory: product.subcategory,
                brand: product.brand,
                subbrand: product.subbrand,
                style: product.style,
                size: product.size,
                color: product.color,
                productname: product.productname,
                sku: product.sku,
                rack: product.rack,
                suppliername: product.supplier,
                purchaserate: product.purchaserate,
                alpharate: product.alpharate,
                discountprice: product.discountprice,
                sellingprice: product.sellingprice,
                quantity: product.quantity,
                from: product.from,
            });
            setShowAlert(
                <>
                    <CheckCircleOutlineIcon sx={{ fontSize: "100px", color: "green" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Added Successfully!"}</p>
                </>
            );
            handleClickOpen();
            handleprint();
            handleCloseModal();

            // setProduct({
            //     ...product, businessname: "", category: "", subcategory: "", brand: "", subbrand: "", style: "", size: "", color: "", productname: "",
            //     sku: "", rack: "", supplier: "", purchaserate: "", alpharate: "", discounttype: "", discountprice: "", sellingprice: '',
            //     quantity: '', from: 0, to: 0, snno: ""
            // })

        } catch (err) {
            console.log(err.message)
            handleApiError(err, setShowAlert, handleClickOpen);
        }
    };

    // print function
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: "print",
    });


    const handleProductCode = (e) => {
        if (e.length > 6) {
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: "orange" }} />
                    <p style={{ fontSize: "20px", fontWeight: 900 }}>{"Product Code can't more than 6 characters!"}</p>
                </>
            );
            handleClickOpen();
            let num = e.slice(0, 6);
            setProduct({ ...product, sku: num })
        }
    }

    return (
        <Box sx={{ padding: "20px" }}>
            {/* <Headtitle title={'product details'} /> */}
            <Typography sx={userStyle.HeaderText}>Product Details</Typography>
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Shop Name <b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>

                            <OutlinedInput
                                value={product.businessname}
                                onChange={(e) => { setProduct({ ...product, businessname: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Category<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>

                            <OutlinedInput
                                value={product.category}
                                onChange={(e) => { setProduct({ ...product, category: e.target.value, productname: `${e.target.value}_${product.subcategory}_${product.brand}_${product.subbrand}_${product.style}_${product.size}_${product.color}` }) }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Sub Category</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.subcategory}
                                onChange={(e) => { setProduct({ ...product, subcategory: e.target.value, productname: `${product.category}_${e.target.value}_${product.brand}_${product.subbrand}_${product.style}_${product.size}_${product.color}` }) }}


                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Brand</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.brand}
                                onChange={(e) => { setProduct({ ...product, brand: e.target.value, productname: `${product.category}_${product.subcategory}_${e.target.value}_${product.subbrand}_${product.style}_${product.size}_${product.color}` }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Sub Brand</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.subbrand}
                                onChange={(e) => { setProduct({ ...product, subbrand: e.target.value, productname: `${product.category}_${product.subcategory == "" ? "ALL" : product.subcategory}_${product.brand}_${e.target.value}_${product.style}_${product.size}_${product.color}` }) }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={9} xs={12} sm={12}>

                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography sx={userStyle.HeaderText}>Variant</Typography>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Style </InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.style}
                                onChange={(e) => { setProduct({ ...product, style: e.target.value, productname: `${product.category}_${product.subcategory}_${product.brand}_${product.subbrand}_${e.target.value}_${product.size}_${product.color}` }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Size</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.size}
                                onChange={(e) => { setProduct({ ...product, size: e.target.value, productname: `${product.category}_${product.subcategory}_${product.brand}_${product.subbrand}_${product.style}_${e.target.value}_${product.color}` }) }}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Colour</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.color}
                                onChange={(e) => { setProduct({ ...product, color: e.target.value, productname: `${product.category}_${product.subcategory}_${product.brand}_${product.subbrand}_${product.style}_${product.size}_${e.target.value}` }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Product Name</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.productname}

                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Product Code<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.sku}
                                onChange={(e) => { setProduct({ ...product, sku: e.target.value }); handleProductCode(e.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Rack</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.rack}
                                onChange={(e) => { setProduct({ ...product, rack: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography sx={userStyle.HeaderText}>Rate Details</Typography>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Supplier Name<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput value={product.supplier}
                                onChange={(e) => { setProduct({ ...product, supplier: e.target.value }) }} />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Purchase Rate<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                placeholder="0.00"
                                type="number"
                                sx={userStyle.input}
                                value={product.purchaserate}
                                onChange={(e) => { setProduct({ ...product, purchaserate: e.target.value, alpharate: "" }) }}
                                onKeyDown={e => exceptThisSymbolsDot.includes(e.key) && e.preventDefault()}

                            />

                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Alpha Rate <b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.alpharate}

                                onChange={getAlpha}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Selling Price <b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.sellingprice}
                                sx={userStyle.input}
                                type="number"
                                placeholder="0.00"
                                onChange={(e) => {
                                    setProduct({ ...product, sellingprice: e.target.value })

                                }}
                                onKeyDown={e => exceptThisSymbolsDot.includes(e.key) && e.preventDefault()}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Discount Price</InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput value={product.discountprice}
                                sx={userStyle.input}
                                type="number"
                                onChange={(e) => { setProduct({ ...product, discountprice: e.target.value }) }} />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12} sm={12}>
                        <Typography sx={userStyle.HeaderText}>Quantity Details</Typography>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >From  <b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput value={product.from}
                                type="number"
                                sx={userStyle.input}
                                onChange={(e) => { setProduct({ ...product, from: e.target.value }) }}
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} />

                        </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12}>
                        <InputLabel >Quantity / To <b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                value={product.quantity}
                                type="number"
                                sx={userStyle.input}
                                onChange={(e) => { setProduct({ ...product, quantity: e.target.value }) }}
                                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} >
                    </Grid>
                    <Grid item md={12} xs={12} marginTop={4} sm={12} sx={{ display: "flex", justifyContent: "center", }}>
                        <Button sx={userStyle.buttonadd} onClick={(e) => { handleOpenModal(true); }}>
                            Print Label
                        </Button>
                        <Button onClick={handleClear} sx={userStyle.buttoncancel} >
                            Clear
                        </Button>


                    </Grid>




                </Grid>
            </Box>

            {/* Error Dialog */}
            <ErrorDialog
                open={isErrorOpen}
                onClose={handleClose}
                showAlert={showAlert}
            />

            {/* Print label Modal */}
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogContent sx={userStyle.filtercontentpopup}>
                    <DialogContentText id="printLabel">
                        <Box >
                            {/* label informations */}
                            <Typography sx={userStyle.importheadtext}>Information to show in Labels</Typography><br /><br />
                            <Box>
                                <form action=''>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Grid sx={{ display: 'flex' }}  >
                                                <Grid sx={userStyle.spanIconTax}><AiOutlineSetting /></Grid>
                                                <FormControl size="small" fullWidth sx={userStyle.formfield}>
                                                    <InputLabel id="demo-select-small">Paper Size</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        label="Paper Size"
                                                        value={productLabel.barcodesetting}
                                                        onChange={(event) => { setProductLabel({ ...productLabel, barcodesetting: event.target.value }) }}
                                                        fullWidth
                                                    >
                                                        <MenuItem value="size1">Label Size: 35mm<ClearIcon sx={{ fontSize: '12px' }} />22mm</MenuItem>
                                                        <MenuItem value="size2">label Size: 25mm<ClearIcon sx={{ fontSize: '12px' }} />25mm</MenuItem>
                                                        <MenuItem value="size3">label Size: 25mm<ClearIcon sx={{ fontSize: '12px' }} />20mm</MenuItem>
                                                        <MenuItem value="size4">label Size: 50mm<ClearIcon sx={{ fontSize: '12px' }} />20mm</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid><br /><br />
                                    {productLabel.barcodesetting === "size1" ?
                                        (
                                            <>
                                                <Grid container >
                                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox checked={productLabel.isProductLocation} onClick={(e) => setProductLabel({ ...productLabel, isProductLocation: !productLabel.isProductLocation })} />} label="Location name" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <FormControlLabel control={<Checkbox checked={productLabel.isProductSellingPrice} onClick={(e) => setProductLabel({ ...productLabel, isProductSellingPrice: !productLabel.isProductSellingPrice })} />} label="Selling Price" />
                                                        </FormGroup>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center', padding: '20px 10px 20px 10px' }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="rackprodcode"
                                                                name="radio-buttons-group"
                                                            >
                                                                <FormControlLabel value="rackprodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrackProductCode: true, isRrack: false, isProductCode: false })} />} label="Rack & Product Code" />
                                                                <FormControlLabel value="rack" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrack: true, isProductCode: false, isRrackProductCode: false })} />} label="Rack" />
                                                                <FormControlLabel value="prodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCode: true, isRrack: false, isRrackProductCode: false })} />} label="Product Code" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center', padding: '20px 10px 20px 10px' }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="numberalpha"
                                                                name="radio-buttons-group"
                                                            >
                                                                {/* <FormControlLabel value="catesubcatecountnumberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: true, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number & Alpharate" /> */}
                                                                {/* <FormControlLabel value="catesubcatecount" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: true, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count" /> */}
                                                                <FormControlLabel value="number" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: true, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number" />
                                                                <FormControlLabel value="alpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: true, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Alpharate" />
                                                                {/* <FormControlLabel value="catesubcatecountnumber" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: true, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number" />
                                                                <FormControlLabel value="catesubcatecountalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: true })} />} label="Category & Subcategory Count & Alpharate" /> */}
                                                                <FormControlLabel value="numberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: true, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number & Alpharate" />

                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={7} lg={7} sx={{ boxShadow: '0px 0px 20px #00000029', padding: '40px 10px 20px 40px' }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="sizebrandsub"
                                                                name="radio-buttons-group"
                                                            >
                                                                <Grid container>
                                                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                        <FormControlLabel value="sizebrandsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandSubbrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Subbrand" />
                                                                        <FormControlLabel value="sizebrandstyle" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandStyle: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Style" />
                                                                        <FormControlLabel value="sizebrandcolor" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandColor: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Color" />
                                                                        <FormControlLabel value="sizestylecolor" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeStyleColor: true, isProductBrandStyle: false, isProductSizeBrandColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Style & Color" />
                                                                        <FormControlLabel value="brandstylecolor" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandStyleColor: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductSizeBrandColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Style & Color" />
                                                                        <FormControlLabel value="brandsubstyle" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrandStyle: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand & Style" />
                                                                        <FormControlLabel value="brandsubcolor" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrandColor: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand & Color" />

                                                                    </Grid>
                                                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                                                        <FormControlLabel value="brandstyle" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandStyle: true, isProductBrandSubbrand: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Style" />
                                                                        <FormControlLabel value="sizebrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, })} />} label="Size & Brand" />
                                                                        <FormControlLabel value="sizesubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeSubbrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Subbrand" />
                                                                        <FormControlLabel value="brandsubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand" />
                                                                        <FormControlLabel value="size" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSize: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size&ensp;&ensp;&ensp;&ensp;&ensp;" />
                                                                        <FormControlLabel value="brand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand&ensp;&ensp;" />
                                                                        <FormControlLabel value="subbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubBrand: true, isProductBrandStyle: false, isProductSizeStyleColor: false, isProductBrandStyleColor: false, isProductBrandSubbrandColor: false, isProductBrandSubbrandStyle: false, isProductSizeBrandColor: false, isProductSizeBrandStyle: false, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSizeBrand: false, })} />} label="Subbrand" />
                                                                    </Grid>
                                                                </Grid>
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={5} lg={5} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center', padding: '40px 10px 20px 40px' }}>
                                                        <FormControl>
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="supcatsub"
                                                                name="radio-buttons-group"
                                                            >
                                                                <FormControlLabel value="supcatsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategorySubcategory: true, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category & Subcategory" />
                                                                <FormControlLabel value="cat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Category" />
                                                                <FormControlLabel value="sub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Subcategory" />
                                                                <FormControlLabel value="sup" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSupplierShortname: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname" />
                                                                <FormControlLabel value="catsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategorySubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, })} />} label="Category & Subcategory" />
                                                                <FormControlLabel value="supcat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category" />
                                                                <FormControlLabel value="supsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Subcategory" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) :
                                        (
                                            productLabel.barcodesetting === "size2" ?
                                                (
                                                    <>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox checked={productLabel.isProductLocation} onClick={(e) => setProductLabel({ ...productLabel, isProductLocation: !productLabel.isProductLocation })} />} label="Location name" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox checked={productLabel.isProductSellingPrice} onClick={(e) => setProductLabel({ ...productLabel, isProductSellingPrice: !productLabel.isProductSellingPrice })} />} label="Selling Price" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="rackprodcode"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="rackprodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrackProductCode: true, isRrack: false, isProductCode: false })} />} label="Rack & Product Code" />
                                                                        <FormControlLabel value="rack" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrack: true, isProductCode: false, isRrackProductCode: false })} />} label="Rack" />
                                                                        <FormControlLabel value="prodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCode: true, isRrack: false, isRrackProductCode: false })} />} label="Product Code" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="numberalpha"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="catesubcatecountnumberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: true, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number & Alpharate" />
                                                                        <FormControlLabel value="catesubcatecount" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: true, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count" />
                                                                        <FormControlLabel value="number" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: true, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number" />
                                                                        <FormControlLabel value="alpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: true, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Alpharate" />
                                                                        <FormControlLabel value="catesubcatecountnumber" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: true, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number" />
                                                                        <FormControlLabel value="catesubcatecountalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: true })} />} label="Category & Subcategory Count & Alpharate" />
                                                                        <FormControlLabel value="numberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: true, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number & Alpharate" />

                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="sizebrandsub"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="sizebrandsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandSubbrand: true, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Subbrand" />
                                                                        <FormControlLabel value="size" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSize: true, isProductSizeBrandSubbrand: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size" />
                                                                        <FormControlLabel value="brand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand" />
                                                                        <FormControlLabel value="subbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSizeBrand: false, })} />} label="Subbrand" />
                                                                        <FormControlLabel value="sizebrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, })} />} label="Size & Brand" />
                                                                        <FormControlLabel value="sizesubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Subbrand" />
                                                                        <FormControlLabel value="brandsubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="supcatsub"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="supcatsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategorySubcategory: true, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category & Subcategory" />
                                                                        <FormControlLabel value="cat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Category" />
                                                                        <FormControlLabel value="sub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Subcategory" />
                                                                        <FormControlLabel value="sup" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSupplierShortname: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname" />
                                                                        <FormControlLabel value="catsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategorySubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, })} />} label="Category & Subcategory" />
                                                                        <FormControlLabel value="supcat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category" />
                                                                        <FormControlLabel value="supsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Subcategory" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : productLabel.barcodesetting === "size3" ?
                                                    (
                                                        <>
                                                            <Grid container>
                                                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel control={<Checkbox checked={productLabel.isProductLocation} onClick={(e) => setProductLabel({ ...productLabel, isProductLocation: !productLabel.isProductLocation })} />} label="Location name" />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel control={<Checkbox checked={productLabel.isProductSellingPrice} onClick={(e) => setProductLabel({ ...productLabel, isProductSellingPrice: !productLabel.isProductSellingPrice })} />} label="Selling Price" />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="rackprodcode"
                                                                            name="radio-buttons-group"
                                                                        >
                                                                            <FormControlLabel value="rackprodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrackProductCode: true, isRrack: false, isProductCode: false })} />} label="Rack & Product Code" />
                                                                            <FormControlLabel value="rack" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrack: true, isProductCode: false, isRrackProductCode: false })} />} label="Rack" />
                                                                            <FormControlLabel value="prodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCode: true, isRrack: false, isRrackProductCode: false })} />} label="Product Code" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="numberalpha"
                                                                            name="radio-buttons-group"
                                                                        >
                                                                            <FormControlLabel value="catesubcatecountnumberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: true, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number & Alpharate" />
                                                                            <FormControlLabel value="catesubcatecount" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: true, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count" />
                                                                            <FormControlLabel value="number" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: true, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number" />
                                                                            <FormControlLabel value="alpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: true, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Alpharate" />
                                                                            <FormControlLabel value="catesubcatecountnumber" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: true, isCateSubCateCountAlpha: false })} />} label="Category & Subcategory Count & Number" />
                                                                            <FormControlLabel value="catesubcatecountalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: false, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: true })} />} label="Category & Subcategory Count & Alpharate" />
                                                                            <FormControlLabel value="numberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isCateSubcateCountNumberAlpha: false, isProductNumberAlpha: true, isNumber: false, isAlpha: false, isCateSubcateCount: false, isCateSubCateCountNumber: false, isCateSubCateCountAlpha: false })} />} label="Number & Alpharate" />

                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            defaultValue="sizebrandsub"
                                                                            name="radio-buttons-group"
                                                                        >
                                                                            <FormControlLabel value="sizebrandsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandSubbrand: true, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Subbrand" />
                                                                            <FormControlLabel value="size" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSize: true, isProductSizeBrandSubbrand: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size" />
                                                                            <FormControlLabel value="brand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand" />
                                                                            <FormControlLabel value="subbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSizeBrand: false, })} />} label="Subbrand" />
                                                                            <FormControlLabel value="sizebrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, })} />} label="Size & Brand" />
                                                                            <FormControlLabel value="sizesubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Subbrand" />
                                                                            <FormControlLabel value="brandsubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    ) : <>
                                                        <Grid container>
                                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox checked={productLabel.isProductLocation} onClick={(e) => setProductLabel({ ...productLabel, isProductLocation: !productLabel.isProductLocation })} />} label="Location name" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox checked={productLabel.isProductSellingPrice} onClick={(e) => setProductLabel({ ...productLabel, isProductSellingPrice: !productLabel.isProductSellingPrice })} />} label="Selling Price" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="rackprodcode"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="rackprodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrackProductCode: true, isRrack: false, isProductCode: false })} />} label="Rack & Product Code" />
                                                                        <FormControlLabel value="rack" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isRrack: true, isProductCode: false, isRrackProductCode: false })} />} label="Rack" />
                                                                        <FormControlLabel value="prodcode" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCode: true, isRrack: false, isRrackProductCode: false })} />} label="Product Code" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="numberalpha"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="numberalpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductNumberAlpha: true, isNumber: false, isAlpha: false })} />} label="Number & Alpharate" />
                                                                        <FormControlLabel value="number" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductNumberAlpha: false, isNumber: true, isAlpha: false })} />} label="Number" />
                                                                        <FormControlLabel value="alpha" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductNumberAlpha: false, isNumber: false, isAlpha: true })} />} label="Alpharate" />

                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="sizebrandsub"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="sizebrandsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrandSubbrand: true, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Brand & Subbrand" />
                                                                        <FormControlLabel value="size" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSize: true, isProductSizeBrandSubbrand: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size" />
                                                                        <FormControlLabel value="brand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand" />
                                                                        <FormControlLabel value="subbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSizeBrand: false, })} />} label="Subbrand" />
                                                                        <FormControlLabel value="sizebrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeBrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, })} />} label="Size & Brand" />
                                                                        <FormControlLabel value="sizesubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSizeSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductBrandSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Size & Subbrand" />
                                                                        <FormControlLabel value="brandsubbrand" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductBrandSubbrand: true, isProductSizeBrandSubbrand: false, isProductSize: false, isProductSizeSubbrand: false, isProductBrand: false, isProductSubBrand: false, isProductSizeBrand: false, })} />} label="Brand & Subbrand" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={4} lg={4} sx={{ boxShadow: '0px 0px 20px #00000029', textAlign: 'center' }}>
                                                                <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        defaultValue="supcatsub"
                                                                        name="radio-buttons-group"
                                                                    >
                                                                        <FormControlLabel value="supcatsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategorySubcategory: true, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category & Subcategory" />
                                                                        <FormControlLabel value="cat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Category" />
                                                                        <FormControlLabel value="sub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Subcategory" />
                                                                        <FormControlLabel value="sup" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductSupplierShortname: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname" />
                                                                        <FormControlLabel value="catsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductCategorySubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, })} />} label="Category & Subcategory" />
                                                                        <FormControlLabel value="supcat" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameCategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameSubcategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Category" />
                                                                        <FormControlLabel value="supsub" control={<Radio onClick={(e) => setProductLabel({ ...productLabel, isProductShortnameSubcategory: true, isProductShortnameCategorySubcategory: false, isProductShortnameCategory: false, isProductCategory: false, isProductSubcategory: false, isProductSupplierShortname: false, isProductCategorySubcategory: false, })} />} label="Supplier Shortname & Subcategory" />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Grid>
                                                            {/* <Grid item xs={12} sm={6} md={4} lg={4}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox checked={productLabel.isProductDiscPrice} onClick={(e) => setProductLabel({...productLabel, isProductDiscPrice:!productLabel.isProductDiscPrice})} />} label="Discounted Price" />
                                                                </FormGroup>
                                                            </Grid> */}
                                                        </Grid>
                                                    </>
                                        )
                                    }<br /><br />
                                    <Grid container sx={{ marginTop: '20px', justifyContent: 'center' }}>
                                        <Grid>
                                            <Button sx={userStyle.buttonadd} onClick={sendRequest}>PRINT</Button>
                                            <Button sx={userStyle.buttonadd} onClick={(e) => setIsQrCodePreview(true)}>PREVIEW</Button>
                                        </Grid>
                                    </Grid>
                                </form><br /><br />
                                {/* print label qr section start */}
                                <div ref={componentRef} style={{ padding: 0, margin: 0, }}>
                                    < Grid container columnSpacing={1} sx={{ display: 'flex', padding: 0, backgroundColor: 'white', }} width="645px">
                                        {productLabel.barcodesetting === "size1" ?
                                            (
                                                isQrCodePreview &&
                                                (() => {

                                                    let rows = [];

                                                    checkAllData.forEach((value, index) => {
                                                        rows.push(
                                                            <>
                                                                <Grid md={3.5} sx={{ margin: 0, padding: '12.5px 2px 0 5px', width: '200px', height: '110px' }}>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}><Qrcodegenerate getProductData={value} productLabel={productLabel} /></Box>
                                                                </Grid>
                                                            </>
                                                        )
                                                    })


                                                    return rows;
                                                })()
                                            ) : productLabel.barcodesetting === "size2" ? (
                                                isQrCodePreview &&
                                                (() => {
                                                    let rows = [];
                                                    checkAllData.forEach((value, index) => {
                                                        rows.push(
                                                            <>
                                                                <Grid item md={3} sx={{ margin: 0, padding: 0, width: '137px', height: '124px' }}>
                                                                    <Qrcodegeneratesize2 getProductData={value} productLabel={productLabel} />
                                                                </Grid>
                                                            </>
                                                        )
                                                    })


                                                    return rows;
                                                })()
                                            ) : productLabel.barcodesetting === "size3" ? (
                                                isQrCodePreview &&
                                                (() => {
                                                    let rows = [];

                                                    checkAllData.forEach((value, index) => {
                                                        rows.push(
                                                            <>
                                                                <Grid item md={3} sx={{ marginTop: '0', paddingTop: '0', width: '137px', height: '130px' }}>
                                                                    <Qrcodegeneratesize3 getProductData={value} productLabel={productLabel} />
                                                                </Grid>
                                                            </>
                                                        )
                                                    })


                                                    return rows;
                                                })()
                                            ) : productLabel.barcodesetting === "size4" ? (
                                                isQrCodePreview &&
                                                (() => {
                                                    let rows = [];

                                                    checkAllData.forEach((value, index) => {
                                                        rows.push(
                                                            <>
                                                                <Grid item md={6} sx={{ marginTop: '0', paddingTop: '0', width: '275px', height: '130px' }}>
                                                                    <Qrcodegeneratesize4 getProductData={value} productLabel={productLabel} />
                                                                </Grid>
                                                            </>
                                                        )
                                                    })


                                                    return rows;
                                                })()) : null
                                        }
                                    </Grid>
                                </div>
                                {/* print label qr section end */}
                            </Box>
                        </Box><br /><br />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleCloseModal}>CLOSE</Button>
                </DialogActions>
            </Dialog>
            {/* Print label Modal Ends */}

        </Box>
    )




}

export default ProductDetails