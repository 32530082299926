import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const ErrorDialog = ({ open, onClose, showAlert }) => {
    const handleClose = () => {
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent sx={{ width: "350px", textAlign: "center", alignItems: "center" }}>
                <Typography style={{ fontSize: '20px', fontWeight: 900 }}>{showAlert}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
