import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import QRCode from 'qrcode';

function Qrcodegenerate({ getProductData, productLabel, id }) {

  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    generateQrCode();
  }, [])

  const generateQrCode = async () => {
    try {
      // const encodedValue = encodeURIComponent('_');

      const response = await QRCode.toDataURL(`${getProductData.productname}`);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  }

 

  let bnname = getProductData.businessname?.slice(0, 15)?.toUpperCase();
  let catspace = getProductData.category?.replace(/[^a-zA-Z0-9]/g, '');
  let subcatspace = getProductData.subcategory?.replace(/[^a-zA-Z0-9]/g, '');
  let catslice12 = catspace?.slice(0, 12);
  let subcatslice12 = subcatspace?.slice(0, 12);
  let catslice = catspace?.slice(0, 6);
  let subcatslice = subcatspace?.slice(0, 6);
  let category = catslice == "" ? "**" : catslice == "" ? "**" : catslice;
  let subcategory = subcatslice == "" ? "**" : subcatslice == "" ? "**" : subcatslice;
  let rescat = category.toUpperCase() + '/' + subcategory.toUpperCase();
  let suppspace = getProductData.supplier?.replace(/[^a-zA-Z0-9]/g, '');
  let suppslice = suppspace?.slice(0, 4);
  let allsuppslice = suppspace?.slice(0, 6);
  let catslicesup = catspace?.slice(0, 4);
  let subcatslicesup = subcatspace?.slice(0, 4);
  let categorys = catslicesup == "" ? "**" : catslicesup == "" ? "**" : catslicesup;
  let subcategorys = subcatslicesup == "" ? "**" : subcatslicesup == "" ? "**" : subcatslicesup;
  let rescatsubsup = suppslice.toUpperCase() + '/' + categorys.toUpperCase() + '/' + subcategorys.toUpperCase();
  let supnamecate = allsuppslice.toUpperCase() + '/' + category.toUpperCase();
  let supnamesubcate = allsuppslice.toUpperCase() + '/' + subcategory.toUpperCase();
  let sizespace = getProductData.size?.replace(/[^a-zA-Z0-9]/g, '');
  let subbrandspace = getProductData.subbrand?.replace(/[^a-zA-Z0-9]/g, '');
  let brandspace = getProductData.brand?.replace(/[^a-zA-Z0-9]/g, '');
  let stylespace = getProductData.style?.replace(/[^a-zA-Z0-9]/g, '');
  let colorspace = getProductData.color?.replace(/[^a-zA-Z0-9]/g, '');
  let brand = brandspace == "" ? "**" : brandspace == "" ? "**" : brandspace;
  let size = sizespace == "" ? "***" : sizespace == "" ? "**" : sizespace;
  let subbrand = subbrandspace == "" ? "***" : subbrandspace == "" ? "**" : subbrandspace;
  let style = stylespace == "" ? "**" : stylespace == "" ? "**" : stylespace;
  let color = colorspace == "" ? "**" : colorspace == "" ? "**" : colorspace;
  let size12 = size?.slice(0, 12);
  let size6 = size?.slice(0, 6);
  let brandslice12 = brand?.slice(0, 12);
  let stylesclice1 = style?.slice(0, 6);
  let colorslice1 = color?.slice(0, 6);
  let subbrandslice12 = subbrand?.slice(0, 12);
  let sizecatslice = size?.slice(0, 4);
  let brandslice = brand?.slice(0, 4);
  let subbrandslice = subbrand?.slice(0, 4);
  let ressizebrandsub = sizecatslice.toUpperCase() + '/' + brandslice.toUpperCase() + '/' + subbrandslice.toUpperCase();
  let sizebrandstyle = sizecatslice.toUpperCase() + '/' + brandslice.toUpperCase() + '/' + stylesclice1?.toUpperCase();
  let sizebrandcolor = sizecatslice.toUpperCase() + '/' + brandslice.toUpperCase() + '/' + colorslice1?.toUpperCase();
  let brandsubbrandstyle = brandslice.toUpperCase() + '/' + subbrandslice.toUpperCase() + '/' + stylesclice1?.toUpperCase();
  let brandsubbrandcolor = brandslice.toUpperCase() + '/' + subbrandslice.toUpperCase() + '/' + colorslice1?.toUpperCase();
  let sizestylecolor = sizecatslice.toUpperCase() + '/' + stylesclice1.toUpperCase() + '/' + colorslice1?.toUpperCase();
  let brandstylecolor = brandslice.toUpperCase() + '/' + stylesclice1.toUpperCase() + '/' + colorslice1?.toUpperCase();
  let sizecatslicesup = size?.slice(0, 4);
  let brandslicesup = brand?.slice(0, 6);
  let subbrandslicesup = subbrand?.slice(0, 6);
  let ressize = sizecatslicesup.toUpperCase() + '/' + brandslicesup.toUpperCase();
  let resbrandstyle = brandslicesup.toUpperCase() + '/' + stylesclice1.toUpperCase();
  let skuid = getProductData?.rack?.length == 2 ? getProductData.sku?.slice(-4) : getProductData?.rack?.length == 3 ? getProductData.sku?.slice(-3) : getProductData?.rack?.length == 4 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 5 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 6 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 7 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 8 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 9 ? getProductData.sku?.slice(-2) : getProductData?.rack?.length == 10 ? getProductData.sku?.slice(-2) : getProductData.sku;
  let respricedatastring = getProductData?.sellingprice;
  let discountprice = getProductData?.discountprice;
  let respricedatasplit = getProductData?.sellingprice?.split('.');
  let pricedatasplit = getProductData?.discountprice?.split('.');
  let respricesuffix = respricedatasplit[1] == undefined ? ".00" : '.' + respricedatasplit[1].slice(0 , 2);
  let dispricedatasplit = getProductData?.discountprice?.split('.');
  let dispricesuffix = pricedatasplit[1] == undefined ? ".00" : '.' + pricedatasplit[1].slice(0 , 2);
  let respricedata = String(respricedatasplit)?.length;
  let discpricedata = String(discountprice)?.length
  let respricedecimaldata = respricedatasplit[0];
  let priceDataPre = dispricedatasplit[0]
  let priceDataPrelength = String(priceDataPre[0])?.length
  let respricedecimaldatalength = String(respricedatasplit[0])?.length;
  let resnodata = String(getProductData?.snno)?.length;
  let rackspace = getProductData.rack?.replace(/[^a-zA-Z0-9]/g, '');

  let rackdata = rackspace?.slice(0, 4);
  let rackdatafinal = rackspace == "" ? "**" : rackspace;
  let rackslice = rackdata == "" ? "**" : rackdata;
  let rescatecount = String(getProductData?.categorycount)?.length
  let categorycountno = String(getProductData?.categorycount)?.length == 4 ?

    getProductData?.categorycount >= 10000 ? '10K' :
      getProductData?.categorycount >= 9000 ? '9K' :
        getProductData?.categorycount >= 8000 ? '8K' :
          getProductData?.categorycount >= 7000 ? '7K' :
            getProductData?.categorycount >= 6000 ? '6K' :
              getProductData?.categorycount >= 5000 ? '5K' :
                getProductData?.categorycount >= 4000 ? '4K' :
                  getProductData?.categorycount >= 3000 ? '3K' :
                    getProductData?.categorycount >= 2000 ? '2K' :
                      getProductData?.categorycount >= 1000 ? '1K' : getProductData?.categorycount : getProductData?.categorycount;

  let result = categorycountno + "/" + getProductData.snno + "#" + getProductData.alpharate
  let resultlength = String(result)?.length
  let countnumber = categorycountno + "/" + getProductData.snno;
  let countnumberlength = String(countnumber)?.length
  let countalpha = categorycountno + "/" + getProductData.alpharate
  let countalphalength = String(countalpha)?.length
  let numberalpha = getProductData.snno + "#" + getProductData.alpharate
  let numberalphalength = String(numberalpha)?.length
  let alphalength = String(getProductData?.alpharate)?.length
  let numberlength = String(getProductData?.snno)?.length
  let catsubcatlength = String(categorycountno)?.length


  return (
    <>
      {/* label size 35mm*22mm */}
      <Box sx={{ margin: 0, position: "relative", padding: 0, overflow: 'hidden' }}>
        {/* business name */}
        <Grid xs={12} md={12} lg={12} sx={12}>
          {productLabel.isProductLocation ? <p className="BusinessLocation" style={{ fontSize: '18px', color: "black", paddingBottom: '2px', fontWeight: 1250, textAlign: 'center' }}><b>{bnname}</b></p> : <p className="BusinessLocation" style={{ fontSize: '18px', color: "black", fontWeight: 1250, textAlign: 'center', visibility: 'hidden' }}><b>{bnname}</b></p>}
        </Grid>


        <Grid container>
          {/* category count, number and alpha */}
          <Grid xs={2} md={2} lg={2} sm={2} sx={{ textAlign: 'center' }}>
            {/* {productLabel.isCateSubcateCountNumberAlpha ?
            <p style={{ marginTop: resultlength == 1 ? '15px' : resultlength == 2 ? '17px' : resultlength == 3 ? '20px' : resultlength == 4 ? '26px' : resultlength == 5 ? '34px' : resultlength == 6 ? '40px' : resultlength == 7 ? '41px' : resultlength == 8 ? '45px' : resultlength == 9 ? '44px' : resultlength == 10 ? '42px' : resultlength >= 11 ? '45px' : '37px', fontSize: resultlength == 1 ? '20px' : resultlength == 2 ? '20px' : resultlength == 3 ? '20px' : resultlength == 4 ? '19px' : resultlength == 5 ? '20px' : resultlength == 6 ? '19px' : resultlength == 7 ? '17px' : resultlength == 8 ? '14px' : resultlength == 9 ? '14px' : resultlength == 10 ? '12px' :  resultlength == 11 ? '11px' : resultlength == 12 ? '10px' : resultlength == 13 ? '9px' : resultlength == 14 ? '8px' : resultlength >= 11 ? '11px' : resultlength >= 20 ? '8px' : "15px", marginLeft: resultlength == 3 ? '3px' : resultlength == 4 ? '3px' : '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase', }}><b>{result}</b></p> :
            productLabel.isCateSubcateCount ?
            <p style={{ marginTop: catsubcatlength == 1 ? '19px' : catsubcatlength >= 5 ? '42px' : '21px', fontSize: catsubcatlength >= 5 ? '14px' : '18px', marginLeft: '5px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{categorycountno}</b></p> :
            productLabel.isNumber ?
            <p style={{ marginTop: numberlength == 4 ? '24px' : '21px', fontSize: '18px', marginLeft: '5px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{getProductData?.snno}</b></p> :
            productLabel.isAlpha ?
            <p style={{ marginTop: alphalength == 1 ? '20px' : alphalength == 3 ? '26px' : alphalength == 4 ? '24px' : '21px', fontSize: alphalength == 3 ? '18px' : alphalength == 4  ? '16px' : '18px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{getProductData.alpharate}</b></p> :
            productLabel.isCateSubCateCountNumber ?
            <p style={{ marginTop: countnumberlength == 3 ? '20px' : countnumberlength == 4 ? '22px' : countnumberlength == 5 ? '29px' : countnumberlength == 6 ? '33px' : countnumberlength == 7 ? '36px' : countnumberlength == 8 ? '39px' : '21px', fontSize: countnumberlength == 3 ? '17px' : countnumberlength == 4 ? '17px' : countnumberlength == 5 ? '17px' : countnumberlength == 6 ? '17px' : countnumberlength == 7 ? '16px' :  countnumberlength >= 3 ? '15px' : '16px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{countnumber}</b></p> :
            productLabel.isCateSubCateCountAlpha ?
            <p style={{marginTop: countalphalength == 3 ? '20px' : countalphalength == 4 ? '21px' : countalphalength == 5 ? '29px' : countalphalength == 6 ? '40px' : countalphalength == 7 ? '36px' : countalphalength == 8 ? '39px' : '21px', fontSize: countalphalength == 3 ? '17px' : countalphalength == 4 ? '17px' : countalphalength == 5 ? '17px' : countalphalength == 6 ? '17px' : countalphalength == 7 ? '15px' : countalphalength == 8 ? '14px' : countalphalength >= 3 ? '15px' : '16px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{countalpha}</b></p> :
            productLabel.isProductNumberAlpha ?
            <p style={{marginTop: numberalphalength == 3 ? '20px' : numberalphalength == 4 ? '21px' : numberalphalength == 5 ? '36px' : numberalphalength == 6 ? '33px' : numberalphalength == 7 ? '41px' : numberalphalength == 8 ? '39px' : '121px', fontSize: numberalphalength == 3 ? '17px' : numberalphalength == 4 ? '17px' : numberalphalength == 5 ? '17px' : numberalphalength == 6 ? '15px' : numberalphalength == 7 ? '15px' : numberalphalength == 8 ? '14px' : numberalphalength >= 3 ? '15px' : '16px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{numberalpha}</b></p> :
            <p style={{ marginTop: numberalphalength >= 2 ? '42px' : '18px', fontSize: numberalphalength >= 2 ? '15px' : '16px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{numberalpha}</b></p>} */}

            {productLabel.isProductNumberAlpha ?
              <p style={{ marginTop: numberalphalength == 3 ? '20px' : numberalphalength == 4 ? '21px' : numberalphalength == 5 ? '36px' : numberalphalength == 6 ? '33px' : numberalphalength == 7 ? '41px' : numberalphalength == 8 ? '39px' : '121px', fontSize: numberalphalength == 3 ? '17px' : numberalphalength == 4 ? '17px' : numberalphalength == 5 ? '17px' : numberalphalength == 6 ? '15px' : numberalphalength == 7 ? '15px' : numberalphalength == 8 ? '14px' : numberalphalength >= 3 ? '15px' : '16px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{numberalpha}</b></p> :
              productLabel.isNumber ?
                <p style={{ marginTop: numberlength == 4 ? '24px' : '21px', fontSize: '18px', marginLeft: '5px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{getProductData?.snno}</b></p> :
                <p style={{ marginTop: alphalength == 1 ? '20px' : alphalength == 3 ? '26px' : alphalength == 4 ? '24px' : '21px', fontSize: alphalength == 3 ? '18px' : alphalength == 4 ? '16px' : '18px', marginLeft: '0px', fontWeight: 1200, transform: 'rotate(-90deg)', color: 'black', position: 'relative', textAlign: 'center', textTransform: 'uppercase' }}><b>{getProductData.alpharate}</b></p>}

          </Grid>
          {/* rack and product code */}
          <Grid xs={1} md={1} lg={1} sm={1} sx={{ textAlign: 'center' }}>
            {productLabel.isRrackProductCode ?
              <p style={{ fontSize: '13px', marginTop: '45px', marginLeft: '-5px', transform: 'rotate(-90deg)', fontWeight: 'bolder', textAlign: 'center', color: 'black', textTransform: 'uppercase' }}><b>{rackslice.toUpperCase() + '/' + skuid.toUpperCase()}</b></p> :
              productLabel.isRrack ?
                <p style={{ fontSize: '16px', marginTop: rackdata?.length == 1 ? '22px' : rackdata?.length == 2 ? '22px' : rackdata?.length == 3 ? '28px' : rackdata?.length == 4 ? '34px' : '42px', marginLeft: '-5px', transform: 'rotate(-90deg)', fontWeight: 'bolder', textAlign: 'center', color: 'black', textTransform: 'uppercase' }}><b>{rackdatafinal.toUpperCase()}</b></p> :
                <p style={{ fontSize: '16px', marginTop: getProductData?.sku?.length == 4 ? '32px' : '39px', marginLeft: '-5px', transform: 'rotate(-90deg)', fontWeight: 'bolder', textAlign: 'center', color: 'black', textTransform: 'uppercase' }}><b>{getProductData.sku?.toUpperCase()}</b></p>}
          </Grid>
          {/* supplier shortname, category, subcategory */}
          <Grid xs={8} md={8} lg={8} sm={8} sx={{ textAlign: 'center' }}>
            <Typography>
              {productLabel.isProductShortnameCategorySubcategory ?
                <p style={{ fontSize: '16px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{rescatsubsup}</b></p> :
                productLabel.isProductCategory ?
                  <p style={{ fontSize: '17px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{catslice12 == "ALL" || catslice12 == "" ? "**" : catslice12}</b></p> :
                  productLabel.isProductSubcategory ? <p style={{ fontSize: '17px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{subcatslice12 == "ALL" || subcatslice12 == "" ? "**" : subcatslice12}</b></p> :
                    productLabel.isProductSupplierShortname ? <p style={{ fontSize: '17px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{allsuppslice}</b></p> :
                      productLabel.isProductCategorySubcategory ? <p style={{ fontSize: '16px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{rescat}</b></p> :
                        productLabel.isProductShortnameCategory ? <p style={{ fontSize: '16px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{supnamecate}</b></p> :
                          <p style={{ fontSize: '17px', marginLeft: '-3px', fontWeight: 1200, color: 'black', marginTop: '-6px', textAlign: 'center', paddingBottom: '4px' }}><b style={{ textTransform: 'uppercase' }}>{supnamesubcate}</b></p>}
            </Typography>

            {/* size, brand, sub brand */}
            <Typography>
              {productLabel.isProductSizeBrandSubbrand ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{ressizebrandsub}</b></p> :
                productLabel.isProductSizeBrandStyle ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{sizebrandstyle}</b></p> :
                  productLabel.isProductSizeBrandColor ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{sizebrandcolor}</b></p> :
                    productLabel.isProductBrandSubbrandStyle ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{brandsubbrandstyle}</b></p> :
                      productLabel.isProductBrandSubbrandColor ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{brandsubbrandcolor}</b></p> :
                        productLabel.isProductSizeStyleColor ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{sizestylecolor}</b></p> :
                          productLabel.isProductBrandStyleColor ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{brandstylecolor}</b></p> :
                            productLabel.isProductBrandStyle ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{resbrandstyle}</b></p> :
                              productLabel.isProductSize ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '17px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{size12}</b></p> :
                                productLabel.isProductBrand ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '17px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{brandslice12}</b></p> :
                                  productLabel.isProductSubBrand ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '17px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{subbrandslice12}</b></p> :
                                    productLabel.isProductSizeBrand ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{size6.toUpperCase() + '/' + brandslicesup.toUpperCase()}</b></p> :
                                      productLabel.isProductSizeSubbrand ? <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{size6.toUpperCase() + '/' + subbrandslicesup.toUpperCase()}</b></p> :
                                        <p className="Alpharate" style={{ paddingBottom: '2px', fontSize: '16px', left: '2px', marginTop: '-6px', position: 'relative', textAlign: 'center', fontWeight: 1200, color: 'black' }}><b>{brandslicesup.toUpperCase() + '/' + subbrandslicesup.toUpperCase()}</b></p>}
            </Typography>

            <Grid container>
              {/* selling price */}
              <Grid xs={7} md={7} lg={7} sm={7} sx={{ textAlign: 'center' }}>
                <Typography>
                  {/* {productLabel.isProductSellingPrice ?
                   getProductData.sellingpricecosttype == "Roundoff" ?  */}

                  {discountprice === "" ?
                    <p className="productSellingPrice" style={{ fontSize: respricedecimaldatalength == 1 ? '36px' : respricedecimaldatalength == 2 ? '36px' : respricedecimaldatalength == 3 ? '32px' : respricedecimaldatalength == 4 ? '24px' : '36px', color: 'black', fontWeight: 1200, textAlign: 'center', marginTop: respricedecimaldatalength == 1 ? '-12px' : respricedecimaldatalength == 2 ? '-12px' : respricedecimaldatalength == 3 ? '-17px' : respricedecimaldatalength == 4 ? '-22px' : '-12px', transformOrigin: 'top', transform: respricedecimaldatalength == 3 ? 'scaleY(1.5)' : respricedecimaldatalength == 4 ? 'scaleY(2)' : 'none', }}><b>{'₹' + respricedecimaldata}<sub style={{ fontSize: respricedecimaldatalength == 1 ? '11px' : respricedecimaldatalength == 2 ? '10px' : '8px', transform: 'none', transformOrigin: 'none' }}>{respricesuffix}</sub></b></p>
                    :
                    <>
                      <span > <p style={{ margin: "-10px" }} ><s style={{ fontSize: "12px", color: "black" }}>{'₹' + respricedecimaldata}{respricesuffix}</s></p></span>
                      <span> <p style={{ margin: "-11px", marginLeft: "0.5px" }}><b style={{ fontSize: priceDataPrelength == 1 ? '23px' : priceDataPrelength == 2 ? '25px' : priceDataPrelength == 3 ? '24px' : priceDataPrelength == 4 ? '23px' : '24px', color: 'black', fontWeight: 1200, textAlign: 'center', marginLeft: priceDataPrelength == 3 ? '7px' : '0px', marginTop: priceDataPrelength == 1 ? '-12px' : priceDataPrelength == 2 ? '-12px' : priceDataPrelength == 3 ? '-17px' : priceDataPrelength == 4 ? '-16px' : '-12px', transformOrigin: 'top', transform: respricedata == 3 ? 'scaleY(1.5)' : discpricedata == 4 ? 'scaleY(1.5)' : 'none', }}>
                        {'₹' + priceDataPre}
                        <sub style={{ fontSize: priceDataPrelength == 1 ? '11px' : priceDataPrelength == 2 ? '10px' : '8px', transform: 'none', transformOrigin: 'none' }}>{dispricesuffix}</sub></b></p></span>


                    </>

                  }








                  {/* <p className="productSellingPrice" style={{ fontSize: respricedata == 1 ? '35px' :  respricedata == 2 ? '36px' : respricedata == 3 ? '32px' : respricedata == 4 ? '28px' : '36px', color: 'black', fontWeight: 1200, textAlign: 'center', marginLeft: respricedata == 3 ? '7px' : '0px', marginTop: respricedata == 1 ? '-12px' : respricedata == 2 ? '-12px' : respricedata == 3 ? '-17px' : respricedata == 4 ? '-16px' : '-12px', transformOrigin: 'top', transform: respricedata == 3 ? 'scaleY(1.5)' : respricedata == 4 ? 'scaleY(1.5)' : 'none' }}><b>{'₹' + respricedatastring}</b></p> */}
                  {/* :  */}
                  {/* getProductData.sellingpricecosttype == "Decimal" ? 
                   <p className="productSellingPrice" style={{ fontSize: respricedecimaldatalength == 1 ? '36px' : respricedecimaldatalength == 2 ? '36px' : respricedecimaldatalength == 3 ? '32px' : respricedecimaldatalength == 4 ? '25px' : '36px', color: 'black', fontWeight: 1200, textAlign: 'center', marginTop: respricedecimaldatalength == 1 ? '-12px' : respricedecimaldatalength == 2 ? '-12px' : respricedecimaldatalength == 3 ? '-17px' : respricedecimaldatalength == 4 ? '-22px' : '-12px', transformOrigin: 'top', transform: respricedecimaldatalength == 3 ? 'scaleY(1.5)' : respricedecimaldatalength == 4 ? 'scaleY(2)' : 'none' }}><b>{'₹' + respricedecimaldata}<sub style={{ fontSize: respricedecimaldatalength == 1 ? '11px' : respricedecimaldatalength == 2 ? '10px' : '8px', transform: 'none', transformOrigin: 'none' }}>{respricesuffix}</sub></b></p>  : 
                   <p className="productSellingPrice" style={{ fontSize: respricedecimaldatalength == 1 ? '36px' : respricedecimaldatalength == 2 ? '36px' : respricedecimaldatalength == 3 ? '32px' : respricedecimaldatalength == 4 ? '25px' : '36px', color: 'black', fontWeight: 1200, textAlign: 'center', marginTop: respricedecimaldatalength == 1 ? '-12px' : respricedecimaldatalength == 2 ? '-12px' : respricedecimaldatalength == 3 ? '-17px' : respricedecimaldatalength == 4 ? '-22px' : '-12px', transformOrigin: 'top', transform: respricedecimaldatalength == 3 ? 'scaleY(1.5)' : respricedecimaldatalength == 4 ? 'scaleY(2)' : 'none' }}><b>{'₹' + respricedecimaldata}<sub style={{ fontSize: respricedecimaldatalength == 1 ? '11px' : respricedecimaldatalength == 2 ? '10px' : '8px', transform: 'none', transformOrigin: 'none' }}>{respricesuffix}</sub></b></p> :
                   <p className="productSellingPrice" style={{ fontSize: respricedecimaldatalength == 1 ? '36px' : respricedecimaldatalength == 2 ? '36px' : respricedecimaldatalength == 3 ? '32px' : respricedecimaldatalength == 4 ? '24px' : '36px', color: 'black', fontWeight: 1200, textAlign: 'center', marginTop: respricedecimaldatalength == 1 ? '-12px' : respricedecimaldatalength == 2 ? '-12px' : respricedecimaldatalength == 3 ? '-17px' : respricedecimaldatalength == 4 ? '-22px' : '-12px', transformOrigin: 'top', transform: respricedecimaldatalength == 3 ? 'scaleY(1.5)' : respricedecimaldatalength == 4 ? 'scaleY(2)' : 'none', visibility:'hidden' }}><b>{'₹' + respricedecimaldata}<sub style={{ fontSize: respricedecimaldatalength == 1 ? '11px' : respricedecimaldatalength == 2 ? '10px' : '8px', transform: 'none', transformOrigin: 'none' }}>{respricesuffix}</sub></b></p> */}
                  {/* }  */}
                </Typography>
                {/* <Typography>  {productLabel.isProductDiscPrice && <p className="ProductDiscoutPrice" style={{fontSize:'17px', color:'black',fontWeight:'bolder', textAlign:'center'}}><b>{'₹ ' + getProductData.}  </Typography> */}
              </Grid>
              <Grid xs={4} md={4} lg={4} sm={4}>
                {/* qr code */}
                <Box sx={{ marginLeft: '10px', marginTop: '2px' }}>
                  {imageUrl ? (
                    <a href={imageUrl} download>
                      <img src={imageUrl} alt="img" width={49} height={49} style={{ marginTop: '-10px' }} />
                    </a>) : null
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

    </>

  );
}


export default Qrcodegenerate;