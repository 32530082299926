import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductDetails from '../webpages/labels/label';


function Webstock() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/easypos" element={<ProductDetails />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Webstock;