import React from 'react';
import "./stylessinternet.css"

function InternetDisable() {
    return (


        <div className="box">
            <div className="symbol"></div>
            <p>Internet connection disabled</p>
            <p>Please check your connection</p>
        </div>


    );
}

export default InternetDisable;